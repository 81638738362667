import { useEffect, useState } from "react";
import "../style/Table.css";
import EventRow from "./EventRow";
import {
  WeekCalculator,
  EventService,
  RealTimeService,
  TimeDifferenceCalculator,
} from "./helpers";
import { EditModal } from "./EditModal";

const Loading = () => {
  return <h3>Loading...</h3>;
};

const EventList = (props) => {
  const { user, newUserEvent, isSaved, setIsSaved } = props;
  const [modalEventToEdit, setModalEventToEdit] = useState({});
  const [loading, setLoading] = useState(true);
  const [userEvents, setUserEvents] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sortingLoading, setSortingLoading] = useState(true);

  const toggleShowEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const templateObject = {
    type: null,
    eventStart: null,
    eventEnd: null,
    userId: null,
    id: null,
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      setIsSaved(false);
      try {
        const originalEvents = await EventService.getUserEvents(user.uid);
        setSortingLoading(true); // Start the sorting operation

        const events = [];

        // here I sort the keypairs in the objects to get a huminized cleaner list to work with
        // Every obj in originalEvents will rearranged according templateObject pushed in 'events'.

        for (const obj of originalEvents) {
          const newObj = { ...templateObject, ...obj };
          events.push(newObj);
        }

        const sortEvents = (singleEvent) => {
          singleEvent.sort(function (a, b) {
            // Extract the date and time components from the event start dates
            var dateA = a.eventStart.split("T")[0];
            var timeA = a.eventStart.split("T")[1];
            var dateB = b.eventStart.split("T")[0];
            var timeB = b.eventStart.split("T")[1];

            // Compare the event dates
            if (dateA > dateB) {
              return -1;
            }
            if (dateA < dateB) {
              return 1;
            }

            // If the event dates are the same, compare the event times in descending order
            if (timeA > timeB) {
              return -1;
            }
            if (timeA < timeB) {
              return 1;
            }

            // If the event times are also the same, maintain the current order
            return 0;
          });

          return events;
        };

        const sortedEvents = sortEvents(events);

        const eventsWithSubtotal = [];

        let currentDate = null;
        let currentWeekNumber = null;
        let subtotal = 0;
        let weeklySubtotal = 0;

        for (const event of events) {
          const [date, startTime] = event.eventStart.split("T");
          const eventDate = new Date(date);
          const eventYear = eventDate.getFullYear(); // Extract year from the event date
          const eventWeekNumber = WeekCalculator.getWeekNumber(eventDate);

          if (currentDate === null) {
            currentDate = date;
            currentWeekNumber = eventWeekNumber;
          }

          if (date !== currentDate) {
            // Create a subtotal row for the previous date
            eventsWithSubtotal.push({
              type: "subtotal",
              eventStart: `${currentDate}T00:00:00.000Z`,
              eventEnd: `${currentDate}T23:59:59.999Z`,
              subtotal: subtotal,
              id: `subtotal-${currentDate}`,
            });
            RealTimeService.writeDayTotal(
              user.uid,
              eventYear,
              currentDate,
              subtotal
            );

            // Reset daily subtotal for the new date
            subtotal = 0;

            if (eventWeekNumber !== currentWeekNumber) {
              // If week has changed, write weekly subtotal for the previous week
              RealTimeService.writeWeekTotal(
                user.uid,
                eventYear,
                currentWeekNumber,
                weeklySubtotal
              );

              // Reset weekly subtotal
              weeklySubtotal = 0;
              currentWeekNumber = eventWeekNumber;
            }

            currentDate = date;
          }

          const eventDuration =
            TimeDifferenceCalculator.calculateDurationInMinutes(
              event.eventStart,
              event.eventEnd
            );
          subtotal += eventDuration;
          weeklySubtotal += eventDuration;

          eventsWithSubtotal.push(event);
        }

        // Create a subtotal row for the last date
        if (currentDate !== null) {
          const yearOfLastDate = new Date(currentDate).getFullYear();
          eventsWithSubtotal.push({
            type: "subtotal",
            eventStart: `${currentDate}T00:00:00.000Z`,
            eventEnd: `${currentDate}T23:59:59.999Z`,
            subtotal: subtotal,
            id: `subtotal-${currentDate}`,
          });

          // Write the weekly total for the last event's week
          RealTimeService.writeWeekTotal(
            user.uid,
            yearOfLastDate,
            currentWeekNumber,
            weeklySubtotal
          );
        }

        setUserEvents(eventsWithSubtotal);
        setSortingLoading(false); // Sorting operation finished
      } catch (error) {
        console.error("Error retrieving events:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();

    // eslint-disable-next-line
  }, [newUserEvent, isSaved]);

  const handleEditEvent = (editEvent, e) => {
    const eventId = e;
    setModalEventToEdit({ eventId: eventId, ...editEvent });
    toggleShowEditModal();
  };

  const handleDeleteEvent = (deletedEventId) => {
    const updatedEvents = userEvents.filter(
      (event) => event.id !== deletedEventId
    );
    setUserEvents(updatedEvents);
    setLoading(false);
  };

  const handleEdit = (e) => {
    console.log(e);
    EventService.getEvent(e).then((result) => {
      handleEditEvent(result, e);
    });
  };
  const handleDelete = async (e) => {
    console.log("Delete", e);
    // console.log(e);
    try {
      EventService.deleteEvent(e);
    } catch (err) {
      console.log(err);
    } finally {
      handleDeleteEvent(e);
      toggleShowEditModal();
      setIsSaved(true);
    }
  };

  if (loading || sortingLoading) {
    return <Loading />; // Render a loading state or placeholder
  }

  return (
    <>
      {showEditModal && (
        <EditModal
          userId={user.uid}
          show={showEditModal}
          title="Edit Event"
          modalEventToEdit={modalEventToEdit}
          toggleShowEditModal={toggleShowEditModal}
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          handleDelete={handleDelete}
        />
      )}
      <table>
        <thead>
          <tr>
            {userEvents.length > 0 ? (
              <>
                <th scope="col">date</th>
                <th scope="col" className="expand">
                  type
                </th>
                <th scope="col">start</th>
                <th scope="col">end</th>
                <th scope="col" className="expand">
                  time
                </th>
                <th scope="col"></th>
              </>
            ) : (
              <>
                <th scope="col">No events found</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" className="expand"></th>
                <th scope="col" className="expand"></th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {userEvents.length > 0 ? (
            userEvents.map((singleEvent) => (
              <tr
                key={singleEvent.id}
                onClick={() => handleEdit(singleEvent.id)}
                className={
                  singleEvent.type === "subtotal" ? "subtotal" : "normal"
                }
              >
                <EventRow
                  id={singleEvent.id}
                  start={singleEvent.eventStart}
                  end={singleEvent.eventEnd}
                  type={singleEvent.type}
                  loading={loading}
                  subtotal={singleEvent.subtotal}
                  setLoading={setLoading}
                  isNew={false}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">
                <h6>Clock in for your first registration</h6>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default EventList;
