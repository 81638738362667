import { useEffect, useState } from "react";
import {
  RealTimeService,
  TimeDifferenceCalculator,
  UserService,
} from "./helpers";

export const TotalsDisplay = (props) => {
  const { user, isSaved } = props; // user here is just uid
  const [weekData, setWeekData] = useState([]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    async function fetchUserData() {
      const data = await UserService.getUserData(user.uid);
      setUserData(data);
    }

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const listener = RealTimeService.attachWeekTotalsListener(
      user.uid,
      (data) => {
        setWeekData(
          data.sort((a, b) => `${a.year}-${a.id}` - `${b.year}-${b.id}`)
        ); // sort weekData by year and week ID
      }
    );

    return () => {
      RealTimeService.detachWeekTotalsListener(user.uid, listener);
    };
  }, [user.uid]);

  const renderTableColumns = (renderContentCallback) => {
    let lastWeekData = weekData.length > 0 ? weekData[0] : null;
    const columns = [];

    weekData.forEach((week) => {
      const currentWeekNumber = parseInt(week.id);
      const lastWeekNumber = lastWeekData ? parseInt(lastWeekData.id) : 0;
      const currentYear = week.year;
      const lastYear = lastWeekData ? lastWeekData.year : currentYear;

      const diff =
        (currentYear - lastYear) * 52 + currentWeekNumber - lastWeekNumber;

      // Handle missing weeks
      if (diff > 1) {
        columns.push(
          <td key={`missing-${currentYear}-${currentWeekNumber - 1}`}>...</td>
        );
      }

      columns.push(renderContentCallback(week));

      lastWeekData = week;
    });

    return columns;
  };

  return (
    <table>
      <tbody>
        <tr>
          <th className="expand">Week</th>
          {renderTableColumns((week) => (
            <td key={`${week.year}-${week.id}`}>{week.id}</td>
          ))}
        </tr>
        <tr>
          <th>Uren</th>
          {renderTableColumns((week) => (
            <td
              key={`${week.year}-${week.id}`}
              className={
                week.total < userData.hoursPerWeek * 60
                  ? "over-hours-limit"
                  : ""
              }
            >
              {TimeDifferenceCalculator.formatHoursMinutes(week.total)}
            </td>
          ))}
        </tr>
        <tr className="difference">
          <th>Verschil</th>
          {renderTableColumns((week) => (
            <td key={`${week.year}-${week.id}`}>
              {week.total > userData.hoursPerWeek * 60 &&
                TimeDifferenceCalculator.formatHoursMinutes(
                  week.total - userData.hoursPerWeek * 60
                )}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
