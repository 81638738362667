// ClockEventContext.js
import React, { createContext, useState, useContext } from "react";

const ClockEventContext = createContext();

export const ClockEventProvider = ({ children }) => {
  const [eventStarted, setEventStarted] = useState(false);
  const [userEvent, setUserEvent] = useState(null);
  const [newUserEvent, setNewUserEvent] = useState(null);

  return (
    <ClockEventContext.Provider
      value={{
        eventStarted,
        setEventStarted,
        userEvent,
        setUserEvent,
        newUserEvent,
        setNewUserEvent,

      }}
    >
      {children}
    </ClockEventContext.Provider>
  );
};

export const useClockEvent = () => {
  const context = useContext(ClockEventContext);
  if (!context) {
    throw new Error("useClockEvent must be used within a ClockEventProvider");
  }
  return context;
};
