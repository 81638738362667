import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//import reportWebVitals from './reportWebVitals';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ClockEventProvider } from "./components/ClockEventContext";
import { ErrorProvider } from "./components/ErrorContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorProvider>
    <ClockEventProvider>
      <App />
    </ClockEventProvider>
  </ErrorProvider>
);
