import { Suspense, useEffect, useState } from "react";
import "../style/Table.css";
import EventList from "../components/EventList";
import { MDBContainer, MDBCard, MDBCardTitle, MDBCardBody } from "mdb-react-ui-kit";
import {
  UserService,
  EventService,
  RealTimeService,
} from "../components/helpers";
import { Modal } from "../components/Modal";
import { Timer } from "../components/Timer";
import { RunningEvent } from "../components/RunningEvent";
import { TotalsDisplay } from "../components/TotalsDisplay";
import { ClockButton } from "../components/ClockButton";
import { useClockEvent } from "../components/ClockEventContext";



const BasePage = (props) => {
  const { admin, user } = props;
  const [userProfile, setUserProfile] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [modalEventToEdit, setModalEventToEdit] = useState();
    const { eventStarted, setEventStarted, newUserEvent, setNewUserEvent } =
      useClockEvent();

  useEffect(() => {
    UserService.getUserData(user.uid)
      .then((userProfile) => {
        setUserProfile(userProfile);
      })
      .catch((error) => {
        console.error("Error retrieving user data:", error);
      });
    RealTimeService.readRunningEvent(user.uid).then((result) => {
      if (result !== undefined) {
        const eventStart = result.eventStart;
        const eventEnd = result.eventEnd;
        const type = result.type;
        const userId = result.userId;

        setNewUserEvent({
          eventStart: eventStart,
          eventEnd: eventEnd,
          type: type,
          userId: userId,
        });
        setEventStarted(true);
      } else {
        setNewUserEvent();
      }
    });
    // eslint-disable-next-line
  }, []);

  let runningEvent = async () => {
    try {
      let result = RealTimeService.readRunningEvent(user.uid);
      return result;
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const toggleShow = () => setModalShow(!modalShow);

  return (
    <div>
      <Modal
        show={modalShow}
        toggleShow={toggleShow}
        modalType={modalType}
        modalEventToEdit={modalEventToEdit}
      />
      <MDBContainer breakpoint="lg">
        <MDBCard className="mt-5">
          <MDBCardBody className="table-wrapper">
            <MDBCardTitle>
              Welcome{" "}
              <span className={admin ? "admin" : "user"}>
                {userProfile.firstName}
              </span>
            </MDBCardTitle>
            <p>
              <br />
            </p>
            <ClockButton user={user} />
            <Suspense fallback={<div>Loading...</div>}>
              <TotalsDisplay user={user} isSaved={isSaved} />
            </Suspense>
          </MDBCardBody>
        </MDBCard>

        {newUserEvent && (
          <MDBCard className="table-wrapper animation">
            <MDBCardBody>
              <RunningEvent user={user} newUserEvent={newUserEvent} />
            </MDBCardBody>
          </MDBCard>
        )}
        <EventList
          isSaved={isSaved}
          setIsSaved={setIsSaved}
          user={user}
          newUserEvent={newUserEvent}
          setNewUserEvent={setNewUserEvent}
          toggleShow={toggleShow}
          setModalType={setModalType}
          setModalEventToEdit={setModalEventToEdit}
        />
      </MDBContainer>
    </div>
  );
};

export default BasePage;
