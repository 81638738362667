import { parseISO } from "date-fns";
import "../style/Table.css";
import {
  DateFormatter,
  TimeDifferenceCalculator,
  WeekCalculator,
} from "./helpers";
import { BsFillPenFill } from "react-icons/bs";

const EventRow = (props) => {
  // console.log("render EventRow");
  const { start, end, type, id, isNew, handleEdit, subtotal } = props;
  const [eventDate, startTime] = start.split("T");
  // console.log("start:", start, "end:", end);

  let date =
    type !== "subtotal"
      ? type !== "weekly-subtotal"
        ? DateFormatter.formatDate(start)
        : "week " + WeekCalculator.getWeekNumber(parseISO(start))
      : "";
  let formatedStart =
    type !== "subtotal" ? DateFormatter.formatTime(start) : "";
  let formatedEnd = type !== "subtotal" ? DateFormatter.formatTime(end) : "";
  let displayType =
    type !== "subtotal" ? (type !== "weekly-subtotal" ? type : "") : "";

  const getDifference = (start, end) => {
    const diff = TimeDifferenceCalculator.calculateDifference(start, end);
    return TimeDifferenceCalculator.formatHoursMinutes(diff);
  };

  let diff =
    type !== "subtotal" ? (
      type !== "weekly-subtotal" ? (
        <div className="diff">{getDifference(start, end)}</div>
      ) : (
        DateFormatter.minutesToHHmm(subtotal)
      )
    ) : (
      <div className="subtotalText">
        {TimeDifferenceCalculator.formatHoursMinutes(subtotal)}
      </div>
    );

  return (
    <>
      <td>{date}</td>
      <td>{displayType}</td>
      <td>{formatedStart}</td>
      {!isNew ? (
        <>
          <td>{formatedEnd}</td>
          <td>{diff}</td>
        </>
      ) : (
        <></>
      )}
      {type !== "subtotal" && type !== "week-subtotal" && !isNew ? (
        <td>
          <span className="actions">
            <BsFillPenFill
              className="edit-btn"
              onClick={() => handleEdit(id)}
            />
          </span>
        </td>
      ) : (
        <>
          <td>
            <span className="actions"></span>
          </td>
        </>
      )}
    </>
  );
};

export default EventRow;
