import EventRow from "./EventRow";

export const RunningEvent = (props) => {
  const { newUserEvent } = props;
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">date</th>
            <th scope="col" className="expand">
              type
            </th>
            <th scope="col" className="expand">
              start
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <EventRow
              isNew={true}
              start={newUserEvent.eventStart}
              end="running"
              type={newUserEvent.type}
            />
          </tr>
        </tbody>
      </table>
    </div>
  );
};
