import { useEffect, useState } from "react";
import backgroundImage from "./img/IMG_5775.jpg";
import "./style/Table.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthorizePage } from "./pages/LoginPage";
import BasePage from "./pages/BasePage";
import UsersPage from "./pages/UsersPage";
import { auth } from "./config/firebase";
import { NavBar } from "./components/NavBar";
import Profile from "./pages/Profile";
import { PopUp } from "./components/PopUp";
import {
  EventService,
  RealTimeService,
  UserService,
} from "./components/helpers";
import { useClockEvent } from "./components/ClockEventContext";

//Geofencing
import { startGeofencing, stopGeofencing } from "./components/Geofence";

// Optionally: You can call stopGeofencing() when you want to stop the geofencing logic.

const version = "v2.2 Geo";

const AppRoute = ({
  path,
  condition,
  redirect,
  children,
  setMessage,
  setUser,
  isAdmin,
  message,
}) => (
  <>
    <NavBar setMessage={setMessage} setUser={setUser} admin={isAdmin} />
    <Route
      path={path}
      element={condition ? children : <Navigate to={redirect || "/"} />}
    />
  </>
);

// Define the layout component.
const Layout = ({
  condition,
  children,
  setMessage,
  setUser,
  isAdmin,
  message,
}) => {
  if (!condition) return <Navigate to="/" />;

  return (
    <>
      <NavBar
        setMessage={setMessage}
        setUser={setUser}
        admin={isAdmin}
        version={version}
      />
      {children}
    </>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const {
    eventStarted,
    setEventStarted,
    userEvent,
    setUserEvent,
    newUserEvent,
    setNewUserEvent,
  } = useClockEvent();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          const result = await UserService.isUserAdmin(user.uid);
          setIsAdmin(result);
        } catch (err) {
          setMessage(err.message);
        }
      })();
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  isAdmin &&
    startGeofencing(
      async () => {
        console.log("Entered the geofence!");

        try {
          const runningEvent = await RealTimeService.readRunningEvent(user.uid);

          if (!runningEvent) {
            const newClockIn = await EventService.startEvent(user.uid);
            setNewUserEvent(newClockIn);
            setEventStarted(true);
          }
        } catch (error) {
          console.error("Error reading running event:", error);
        }
      },
      () => {
        console.log("Exited the geofence!");
        // Add your logic for when the device exits the geofence
        // stopEvent();
      },
      "Office"
    );
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <Router>
        <Routes>
          <Route
            path="/"
            default
            element={
              user ? (
                <Navigate to={isAdmin ? "/admin" : "/base"} />
              ) : (
                <AuthorizePage
                  message={message}
                  setMessage={setMessage}
                  version={version}
                />
              )
            }
          />
          <Route
            path="/base"
            element={
              <Layout
                condition={user}
                setMessage={setMessage}
                setUser={setUser}
                isAdmin={isAdmin}
                message={message}
              >
                <BasePage
                  user={user}
                  admin={isAdmin}
                  message={message}
                  setMessage={setMessage}
                />
              </Layout>
            }
          />
          <Route
            path="/admin"
            element={
              <Layout
                condition={isAdmin}
                setMessage={setMessage}
                setUser={setUser}
                isAdmin={isAdmin}
                message={message}
              >
                <BasePage
                  user={user}
                  admin={isAdmin}
                  message={message}
                  setMessage={setMessage}
                />
              </Layout>
            }
          />
          <Route
            path="/users"
            element={
              <Layout
                condition={true}
                setMessage={setMessage}
                setUser={setUser}
                isAdmin={isAdmin}
                message={message}
              >
                <UsersPage
                  user={user}
                  admin={isAdmin}
                  message={message}
                  setMessage={setMessage}
                />
              </Layout>
            }
          />
          <Route
            path="/profile"
            element={
              <Layout
                condition={true}
                setMessage={setMessage}
                setUser={setUser}
                isAdmin={isAdmin}
                message={message}
              >
                <Profile
                  user={user}
                  admin={isAdmin}
                  message={message}
                  setMessage={setMessage}
                />
              </Layout>
            }
          />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
