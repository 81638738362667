// Firebase imports
import {
  collection,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  query,
  where,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import {
  EmailAuthProvider,
  signOut,
  reauthenticateWithCredential,
  updatePassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { set, ref, remove, get, off, onValue } from "firebase/database";
import { db, rtdb, auth } from "../config/firebase";
import { parseISO } from "date-fns";

// Date-related imports
import moment from "moment";
import "moment/locale/nl";
import { useClockEvent } from "./ClockEventContext";
const { getISOWeek } = require("date-fns");

moment.updateLocale("nl", {
  weekdaysShort: ["zo", "ma", "di", "wo", "do", "vr", "za"],
});

class DateFormatter {
  static formatDate = (date) => {
    if (moment.isDate(date)) {
      return moment(date).format("ddd DD-MM");
    }

    if (moment(date, moment.ISO_8601, true).isValid()) {
      const isoDate = moment.utc(date);
      return isoDate.local().format("ddd DD-MM");
    }

    return DateFormatter.formatFireStoreDate(date);
  };

  static minutesToHHmm = (minutes) => {
    const hours = Math.floor(minutes / 60);
    return moment().startOf("day").add(hours, "hours").format("HH:mm");
  };

  static formatTime = (date) => {
    if (moment.isDate(date)) {
      return moment(date).format("HH:mm");
    }

    if (moment(date, moment.ISO_8601, true).isValid()) {
      return moment.utc(date).local().format("HH:mm");
    }

    return DateFormatter.formatFireStoreTime(date);
  };

  static formatFireStoreDate = (unixTime) => {
    if (!unixTime || unixTime === "running") {
      return "no date";
    }

    const { seconds, nanoseconds } = unixTime;
    const date = moment
      .unix(seconds)
      .add(nanoseconds / 1000000, "milliseconds");
    return date.format("ddd DD-MM");
  };

  static formatFireStoreTime = (unixTime) => {
    if (!unixTime || unixTime === "running") {
      return "no time";
    }

    const { seconds, nanoseconds } = unixTime;
    const time = moment
      .unix(seconds)
      .add(nanoseconds / 1000000, "milliseconds");
    return time.format("HH:mm");
  };

  static ObjectConverter = (inputObject, userId) => {
    const { date, start, endDate, end, type } = inputObject;

    // Combine date and time strings
    const eventStart = `${date}T${start}:00.000`;
    const eventEnd =
      date !== endDate ? `${endDate}T${end}:00.000` : `${date}T${end}:00.000`;

    // Convert to Moment objects in local timezone
    const startMoment = moment(eventStart);
    const endMoment = moment(eventEnd);

    // Convert to UTC
    return {
      eventStart: startMoment.utc().toISOString(),
      eventEnd: endMoment.utc().toISOString(),
      type,
      userId,
    };
  };
}

class WeekCalculator {
  static getWeekNumber(date) {
    if (moment(date, moment.ISO_8601, true).isValid()) {
      return getISOWeek(date);
    } else {
      return null;
    }
  }

  static getWeekStartDate(date) {
    const day = date.getDay();
    const daysToSubtract = day === 0 ? 6 : day - 1; // Adjust when day is Sunday
    const weekStart = new Date(date);
    weekStart.setDate(date.getDate() - daysToSubtract);
    weekStart.setHours(0, 0, 0, 0);
    return weekStart;
  }

  static getWeekEndDate(date) {
    const day = date.getDay();
    const daysToAdd = day === 0 ? 0 : 7 - day; // Adjust when day is Sunday
    const weekEnd = new Date(date);
    weekEnd.setDate(date.getDate() + daysToAdd);
    weekEnd.setHours(23, 59, 59, 999);
    return weekEnd;
  }
}

class TimeDifferenceCalculator {
  // Update the calculateDifference method
  static calculateDifference(startUnixTime, endUnixTime) {
    const isJavaScriptDate = (date) => {
      return date instanceof Date;
    };

    const isISO8601 = (date) => {
      const iso8601Pattern =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?([+-]\d{2}:\d{2}|Z)$/;
      return iso8601Pattern.test(date);
    };

    const isUnixTime = (date) => {
      return !isNaN(date) && isFinite(date);
    };

    const convertToJavaScriptDate = (date) => {
      if (isISO8601(date)) {
        return new Date(date);
      }
      if (isUnixTime(date)) {
        return new Date(date * 1000);
      }
      return date;
    };

    let time1 = convertToJavaScriptDate(startUnixTime);
    let time2 = convertToJavaScriptDate(endUnixTime);

    if (!isJavaScriptDate(time1)) {
      time1 = new Date(time1);
    }

    if (!isJavaScriptDate(time2)) {
      time2 = new Date(time2);
    }

    const difference = time2.getTime() - time1.getTime();
    const difInMinutes = Math.round(difference / 1000 / 60);

    return difInMinutes; // Return duration in minutes as a number
  }

  static calculateDurationInMinutes = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const durationInMinutes = (endTime.getTime() - startTime.getTime()) / 60000;
    return durationInMinutes;
  };

  static formatHoursMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.ceil(minutes % 60);
    const formattedTime = `${hours
      .toString()
      .padStart(2, "0")}:${remainingMinutes.toString().padStart(2, "0")}`;
    return formattedTime;
  };
}

class UserService {
  static async isUserAdmin(uid) {
    const usersRef = collection(db, "users");

    const userDoc = await getDoc(doc(usersRef, uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      if (userData.role === "admin") {
        return true;
      } else {
        return false;
      }
    } else {
      // console.log("User not found");
    }
  }

  static async editUserData(id, update) {
    const usersRef = collection(db, "users");
    const userDocRef = doc(usersRef, id);

    try {
      await updateDoc(userDocRef, update);
      return true; // Return true if the update was successful
    } catch (error) {
      console.error(error);
      throw error;
      return false; // Return false if an error occurred during the update
    }
  }

  static async getUserData(uid) {
    const usersRef = collection(db, "users");
    const userDoc = await getDoc(doc(usersRef, uid));
    const userData = userDoc.data();
    return userData;
  }

  static async changeUserPassword(user, currentPassword, newPassword) {
    try {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
    } catch (error) {
      console.error("Error during reauthentication:", error);
      throw error;
    }

    try {
      await updatePassword(auth.currentUser, newPassword);
      return true;
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  }

  static async signOutUser() {
    // console.log("signOutUser");
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async sendPasswordResetLink(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  }
}

class ClockEventService {
  static async handleClockIn(user, setEventStarted, setNewUserEvent) {
    try {
      const newClockIn = await EventService.startEvent(user.uid);
      setNewUserEvent(newClockIn);
      setEventStarted(true);
    } catch (err) {
      console.error("Error clocking in:", err);
    }
  }

  static async handleClockOut(
    user,
    newUserEvent,
    setEventStarted,
    setNewUserEvent
  ) {
    try {
      await EventService.stopEvent(user.uid, newUserEvent);
      setEventStarted(false);
      setNewUserEvent(null); // Reset this to a default value or undefined
    } catch (err) {
      console.error("Error clocking out:", err);
    }
  }
}


class EventService {
  static async startEvent(uid) {
    console.log("incoming uid:", uid);
    const start = new Date();
    const newEvent = {
      userId: uid,
      type: "Rijden",
      eventStart: start.toISOString(),
      eventEnd: "running",
    };

    try {
      await RealTimeService.writeRunningEvent(uid, newEvent);
      return newEvent;
    } catch (err) {
      console.error("Error starting event:", err);
      throw err;
    }
  }

  static async stopEvent(uid, runningEvent) {
    const end = new Date();
    const stoppedEvent = { ...runningEvent, eventEnd: end.toISOString() };

    try {
      await this.addEvent(stoppedEvent);
      await RealTimeService.deleteRunningEvent(uid);
      return stoppedEvent;
    } catch (err) {
      console.error("Error stopping event:", err);
      throw err;
    }
  }
  static async getUserEvents(uid) {
    const q = query(collection(db, "events"), where("userId", "==", uid));

    return new Promise((resolve, reject) => {
      onSnapshot(
        q,
        (snapshot) => {
          const col = [];
          snapshot.docs.forEach((doc) => {
            col.push({ ...doc.data(), id: doc.id });
          });
          // console.log("eventservice getuserevents:", col);
          resolve(col); // Resolve the promise with the collected events
        },
        reject
      ); // Reject the promise if an error occurs
    });
  }

  static async getOpenEventDocId(uid) {
    const userEvents = await this.getUserEvents(uid);
    // console.log(userEvents);
    let sortedEvents = [];
    if (userEvents?.length > 1) {
      // console.log("userEvents is longer that 1");
      sortedEvents = userEvents.sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : -1
      );
      const openEvents = sortedEvents.filter(
        (event) => event.userId === uid && event.endTime === "running"
      );

      if (openEvents.length > 0) {
        // console.log("openEvents > 0");
        const lastOpenEvent = openEvents[openEvents.length - 1];
        return lastOpenEvent.id;
      } else {
        // console.log("openEents = 0");
        return null;
      }
    } else {
      // console.log("userEvents = 1");
      return userEvents;
    }
  }

  static async getEvent(id) {
    console.log("getEvent: ", id);
    const eventRef = doc(collection(db, "events"), id);
    try {
      const eventDoc = await getDoc(eventRef);
      const eventData = eventDoc.data();
      console.log("getEvent: ", eventData);
      return eventData;
    } catch (error) {
      console.error(error);
    }
  }

  static async deleteEvent(id) {
    const eventRef = doc(collection(db, "events"), id);
    await deleteDoc(eventRef);
  }

  static async editEvent(id, updates) {
    console.log(id, updates);
    const eventRef = doc(collection(db, "events"), id);
    await updateDoc(eventRef, updates);
  }

  static async setEventEndTime(eventId, newEndTime) {
    const eventRef = doc(collection(db, "events"), eventId);

    await updateDoc(eventRef, {
      endTime: newEndTime,
    });
  }

  static async addEvent(newEvent) {
    // console.log("addEvent:", newEvent);
    const eventRef = collection(db, "events");
    await addDoc(eventRef, newEvent);
  }

  static async getEventsByWeekNumberAndYear(uid, targetYear, targetWeekNumber) {
    // First, get all events for the user
    const q = query(collection(db, "events"), where("userId", "==", uid));

    return new Promise((resolve, reject) => {
      onSnapshot(
        q,
        (snapshot) => {
          const col = [];
          snapshot.docs.forEach((doc) => {
            const event = doc.data();
            const eventDate = parseISO(event.eventStart);
            const eventWeekNumber = WeekCalculator.getWeekNumber(eventDate);
            const eventYear = eventDate.getFullYear(); // Extracting the year from the event date

            if (
              eventWeekNumber === targetWeekNumber &&
              eventYear === targetYear
            ) {
              col.push({ ...event, id: doc.id });
            }
          });
          // Resolve the promise with the collected events for the specific weekNumber and year
          resolve(col);
        },
        reject // Reject the promise if an error occurs
      );
    });
  }
}

class RealTimeService {
  static async writeRunningEvent(userId, data) {
    set(ref(rtdb, `/${userId}/runningEvent`), { data: data });
  }

  static async writeWeekTotal(userId, year, week, weekTotal) {
    set(ref(rtdb, `/${userId}/week/${year}/${week}`), { total: weekTotal });
  }

  static async writeDayTotal(userId, year, day, dayTotal) {
    set(ref(rtdb, `/${userId}/day/${year}/${day}`), { total: dayTotal });
  }

  static async readRunningEvent(userId) {
    const snapshot = await get(ref(rtdb, `/${userId}/runningEvent`));
    if (snapshot.exists()) {
      return snapshot.val().data;
    } else {
      return undefined; // or any other default value you prefer
    }
  }

  static async removeWeek(userId, year, weekNumber) {
    try {
      await remove(ref(rtdb, `/${userId}/week/${year}/${weekNumber}`));
    } catch (error) {
      console.error("Failed to remove the week:", error);
    }
  }

  static async getWeekTotals(userId) {
    const snapshot = await get(ref(rtdb, `/${userId}/week/`));
    const yearsData = snapshot.val();
    const resultArray = [];

    if (snapshot.exists() && yearsData) {
      for (const year in yearsData) {
        for (const week in yearsData[year]) {
          resultArray.push({
            id: week,
            year: year,
            total: yearsData[year][week].total,
          });
        }
      }
      return resultArray;
    } else {
      return undefined;
    }
  }

  static attachWeekTotalsListener(userId, callback) {
    const weekRef = ref(rtdb, `/${userId}/week/`);
    const listener = onValue(weekRef, (snapshot) => {
      const yearsData = snapshot.val();
      const resultArray = [];

      if (yearsData) {
        for (const year in yearsData) {
          for (const week in yearsData[year]) {
            resultArray.push({
              id: week,
              year: year,
              total: yearsData[year][week].total,
            });
          }
        }
      }

      callback(resultArray);
    });

    return listener;
  }

  static detachWeekTotalsListener(userId, listener) {
    const weekRef = ref(rtdb, `/${userId}/week/`); // changed the name from ref to weekRef
    off(weekRef, listener);
  }

  static async deleteRunningEvent(userId) {
    remove(ref(rtdb, `/${userId}/runningEvent`));
  }
}

export {
  UserService,
  TimeDifferenceCalculator,
  WeekCalculator,
  DateFormatter,
  EventService,
  RealTimeService,
  ClockEventService,
};
