import { isPointWithinRadius } from "geolib";

let insideOfficeTimer = null;
let outsideOfficeTimer = null;

const DaskoApeldoorn = {
  laltidude: 52.1763236914718,
  longitude: 5.972934917729484
};

const homeLocation = {
  latitude: 52.2115417,
  longitude: 5.9556838,
};

export const startGeofencing = (onEnter, onExit, placeToCheck) => {
  let geoFence = {};
  switch (placeToCheck) {
    case "Office":
      console.log("office");
      geoFence = DaskoApeldoorn;
      break;
    case "Home":
      console.log("home");
      geoFence = homeLocation;
      break;
    default:
      geoFence = {
        latitude: 0,
        longitude: 0,
      };
      break;
  }

  console.log(
    "GeoFencing started with pos",
    geoFence.latitude,
    geoFence.longitude
  );

  navigator.geolocation.watchPosition(
    (position) => {
      const deviceLocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      const isWithinGeoFence = isPointWithinRadius(
        deviceLocation,
        geoFence,
        100
      );
      console.log(
        "Current position: ",
        deviceLocation.latitude,
        deviceLocation.longitude
      );

      if (isWithinGeoFence) {
        // User entered the office geofence
        console.log("user is within geoFence");
        if (!insideOfficeTimer) {
          insideOfficeTimer = setTimeout(() => {
            onEnter();

            // Clear timers
            clearTimeout(outsideOfficeTimer);
            outsideOfficeTimer = null;
          }, 0.5 * 60 * 1000);
        }
      } else {
        // User exited the office geofence
        console.log("user is outside geoFence");
        if (insideOfficeTimer) {
          clearTimeout(insideOfficeTimer);
          insideOfficeTimer = null;
        } else {
          if (!outsideOfficeTimer) {
            outsideOfficeTimer = setTimeout(() => {
              onExit();

              // Clear timers
              clearTimeout(insideOfficeTimer);
              insideOfficeTimer = null;
            }, 0.5 * 60 * 1000);
          }
        }
      }
    },
    (error) => {
      console.error("Error watching position: ", error);
    },
    {
      enableHighAccuracy: true,
      timeout: 500,
      maximumAge: 0,
    }
  );
};

export const stopGeofencing = () => {
  // Clean up any ongoing timers
  if (insideOfficeTimer) {
    clearTimeout(insideOfficeTimer);
    insideOfficeTimer = null;
  }

  if (outsideOfficeTimer) {
    clearTimeout(outsideOfficeTimer);
    outsideOfficeTimer = null;
  }

  // Optionally: Stop the geolocation watcher if you've kept its ID.
};
