import "../style/Modal.scss";
export const ErrorModal = ({ message, onClose, messageType }) => {
  const getTitle = () => {
    switch (messageType) {
      case "error":
        return "Error";
      case "success":
        return "Great!";
      default:
        return "";
    }
  };

  const getModalStyle = () => {
    switch (messageType) {
      case "error":
        return "border-danger";
      case "success":
        return "border-success";
      default:
        return "";
    }
  };

  return (
    <div
      className="modal modal-wrapper fade show d-block"
      id="messageModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="messageModalLabel"
      aria-modal="true"
      aria-hidden="true"
    >
      {" "}
      <div className="modal-backdrop fade show"> </div>
      <div className="modal-dialog" role="document">
        <div className={`modal-content ${getModalStyle()}`}>
          <div className="modal-header">
            <h5 className="modal-title" id="messageModalLabel">
              {getTitle()}
            </h5>
          </div>
          <div className="modal-body">{message}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
