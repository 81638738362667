import { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
} from "mdb-react-ui-kit";
import { storage } from "../config/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { UserService } from "./helpers";

export const NavBar = ({ admin, logoOnly, setUser, setMessage, version }) => {
  const [logoURL, setLogoURL] = useState("");

  useEffect(() => {
    fetchLogoURL();
  }, []);

  const fetchLogoURL = async () => {
    try {
      const logoRef = ref(storage, "images/CICO_Logo.png");
      const result = await getDownloadURL(logoRef);
      setLogoURL(result);
    } catch (error) {
      console.error("Error fetching logo URL:", error);
      // You can set a user-friendly message if needed.
      setMessage("Error fetching logo");
    }
  };

  const onLogOut = () => {
    try {
      UserService.signOutUser();
      setUser(null);
    } catch (err) {
      setMessage(err);
    }
  };

  return (
    <div>
      <MDBNavbar
        sticky
        expand="sm"
        light
        style={{ backgroundColor: "#e3f2fd" }}
      >
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">
            <img src={logoURL} height="60" alt="" loading="lazy" />
          </MDBNavbarBrand>
          <NavigationItems
            admin={admin}
            logoOnly={logoOnly}
            onLogOut={onLogOut}
          />
          <div>
            <small>{version}</small>
          </div>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

const NavigationItems = ({ admin, logoOnly, onLogOut }) => {
  if (logoOnly) return null;

  return (
    <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
      <NavigationLink href="/" label="Home" />
      <NavigationLink href="profile" label="UserProfile" />
      {admin && <NavigationLink href="users" label="Users" />}
      <LogoutButton onLogOut={onLogOut} />
    </MDBNavbarNav>
  );
};

const NavigationLink = ({ href, label }) => (
  <MDBNavbarItem className="mx-1 my-0">
    <MDBNavbarLink active={href === "/"} aria-current="page" href={href}>
      {label}
    </MDBNavbarLink>
  </MDBNavbarItem>
);

const LogoutButton = ({ onLogOut }) => (
  <div className="d-flex justify-content-end">
    <MDBBtn color="primary" onClick={onLogOut} className="mx-1 my-0">
      Log out
    </MDBBtn>
  </div>
);
