import React from "react";
import { RealTimeService, EventService } from "../components/helpers";
import { Timer } from "../components/Timer";
import { useClockEvent } from "./ClockEventContext";

export const ClockButton = ({
  user
}) => {
  const { eventStarted, setEventStarted, newUserEvent, setNewUserEvent } =
    useClockEvent();

  const handleClockIn = async () => {
    try {
      const newClockIn = await EventService.startEvent(user.uid);
      setNewUserEvent(newClockIn);
      setEventStarted(true);
    } catch (err) {
      console.error("Error clocking in:", err);
    }
  };

  const handleClockOut = async () => {
    try {
      await EventService.stopEvent(user.uid, newUserEvent);
      setEventStarted(false);
      setNewUserEvent(); // You might want to reset this to a default value or undefined
    } catch (err) {
      console.error("Error clocking out:", err);
    }
  };

  return (
    <span className="cico-btn-group d-flex justify-content-center mb-5">
      <button
        className="clock-in-btn"
        onClick={handleClockIn}
        disabled={eventStarted}
      >
        {eventStarted ? (
          newUserEvent ? <Timer eventStart={newUserEvent.eventStart} />
        : (<div>Loading...</div>)) : (
          <>Clock in</>
        )}
      </button>
      <button
        className="clock-out-btn"
        onClick={handleClockOut}
        disabled={!eventStarted}
      >
        Clock out
      </button>
    </span>
  );
};
