import {
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBBtn,
  MDBCol,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { DateFormatter, EventService, RealTimeService } from "./helpers";
import moment from "moment";
import "../style/Modal.css";

export const EditModal = (props) => {
  const {
    userId,
    title,
    show,
    toggleShowEditModal,
    modalEventToEdit,
    isSaved,
    setIsSaved,
    handleDelete,
  } = props;

  const initialEvent = {
    date: "",
    start: "",
    endDate: "",
    end: "",
    type: "Rijden",
  };

  const [form, setForm] = useState(initialEvent);

  console.log("Edit Modal event: ", modalEventToEdit);

  useEffect(() => {
    if (modalEventToEdit) {
      // Extract date and time values using Moment.js
      const eventStart = moment(modalEventToEdit.eventStart);
      const eventEnd = moment(modalEventToEdit.eventEnd);

      // Format the date and time values
      const formattedDate = eventStart.format("YYYY-MM-DD");
      const formattedStartTime = eventStart.format("HH:mm");
      const formattedEndTime = eventEnd.format("HH:mm");
      const formattedEndDate = eventEnd.format("YYYY-MM-DD");

      // Set the formatted values as initial form values
      setForm((prevEvent) => ({
        ...prevEvent,
        date: formattedDate,
        start: formattedStartTime,
        endDate: formattedEndDate,
        end: formattedEndTime,
        type: modalEventToEdit.type,
      }));
    }
  }, [modalEventToEdit]);

  const deleteEventAndWeek = async (eventId) => {
    // 1. Identify the event's week number and year
    const eventDate = moment(modalEventToEdit.eventStart);
    const eventWeek = eventDate.week();
    const eventYear = eventDate.year();

    // 2. Delete the event using existing logic
    await EventService.deleteEvent(eventId);

    // 3. Check if there are any other events with the same week number and year
    const eventsWithSameWeek = await EventService.getEventsByWeekNumberAndYear(
      userId,
      eventYear,
      eventWeek
    ); // Update this method to retrieve events by both week number and year
    console.log("eventsWithSameWeek:", eventsWithSameWeek);
    // If there are no other events with that week number and year, delete the week entry from the Realtime database
    if (!eventsWithSameWeek.length) {
      RealTimeService.removeWeek(userId, eventYear, eventWeek);
      // Update this method in your RealTimeService to remove data using both week number and year
    }
    setIsSaved(true);
    setForm(initialEvent);
    toggleShowEditModal();
  };

  const handleSave = async () => {
    setIsSaved(false);
    console.log("handleSave: form", form);
    const convertedObject = DateFormatter.ObjectConverter(form, userId);
    console.log("converted Object: ", convertedObject);
    try {
      EventService.editEvent(modalEventToEdit.eventId, convertedObject);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaved(true);
      toggleShowEditModal();
      setForm(initialEvent);
    }
  };

  const handleChange = (e) => {
    console.log(form);
    const { name, value } = e.target;
    let prev = form;
    switch (name) {
      case "type":
        let selectedType = value;
        setForm({ ...prev, type: selectedType });
        break;
      case "date":
        setForm({ ...prev, date: value });
        break;
      case "endDate":
        setForm({ ...prev, endDate: value });
        break;
      default:
        setForm({ ...prev, [name]: value });
        break;
    }
  };

  return (
    <div>
      <MDBModal show={show}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{title}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowEditModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="form">
                <div className="row">
                  <div className="col">
                    <label htmlFor="date">Date:</label>
                  </div>
                  <div className="col">
                    <input
                      name="date"
                      type="date"
                      id="date"
                      value={form.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor="start">Start Time:</label>
                  </div>
                  <div className="col">
                    <input
                      name="start"
                      type="time"
                      id="start"
                      value={form.start}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor="date">End date:</label>
                  </div>
                  <div className="col">
                    <input
                      name="endDate"
                      type="date"
                      id="endDate"
                      value={form.endDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor="end">End Time:</label>
                  </div>
                  <div className="col">
                    <input
                      name="end"
                      type="time"
                      id="end"
                      value={form.end}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label htmlFor="type">Type:</label>
                  </div>
                  <div className="col">
                    <select
                      className="select"
                      name="type"
                      id="type"
                      value={form.type}
                      onChange={handleChange}
                    >
                      <option value="rijden">rijden</option>
                      <option value="uitgifte">uitgifte</option>
                      <option value="inname">inname</option>
                      <option value="laden">laden</option>
                      <option value="kantoor">kantoor</option>
                      <option value="overige">overige</option>
                    </select>
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBCol>
                <MDBBtn
                  color="danger"
                  onClick={() => deleteEventAndWeek(modalEventToEdit.eventId)}
                >
                  Delete
                </MDBBtn>
              </MDBCol>
              <MDBCol className="d-flex flex-row-reverse">
                <MDBBtn onClick={handleSave}>Save</MDBBtn>
                <MDBBtn color="secondary" onClick={toggleShowEditModal}>
                  Cancel
                </MDBBtn>
              </MDBCol>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};
