// React imports
import React, { useState } from "react";
import "../style/Table.css";

// Context imports
import { useClockEvent } from "../components/ClockEventContext";
import { useError } from "../components/ErrorContext";

// Firebase imports
import { auth, db } from "../config/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { collection, setDoc, doc } from "firebase/firestore";

// MDBootstrap imports
import {
  MDBContainer,
  MDBCard,
  MDBCardTitle,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBCheckbox,
} from "mdb-react-ui-kit";

// Custom Component imports
import { UserService } from "../components/helpers";

const CustomInput = ({ type, value, label, onChange }) => (
  <MDBInput
    size="lg"
    className="mb-4"
    type={type}
    value={value}
    label={label}
    onChange={onChange}
  />
);

export const AuthorizePage = ({ message, setMessage, version }) => {
  const [signUp, setSignUp] = useState(false);
  const { showError } = useError();

  return (
    <>
      {signUp ? (
        <SignInDialog {...{ message, setMessage, setSignUp }} />
      ) : (
        <LoginDialog {...{ message, setMessage, setSignUp }} />
      )}
      <div className="version">{version}</div>
    </>
  );
};

const LoginDialog = ({ setSignUp, message, setMessage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { showError, showSuccess } = useError();

  const signInUser = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log(error);
      showError(error.code, "Die fout hadden we niet verwacht");
    }
  };

  const handleForgotPassword = async () => {
    // const email = email;

    try {
      await UserService.sendPasswordResetLink(email);
      // Show success message or perform other actions
      showSuccess("success/email-sent");
    } catch (error) {
      console.log(error);
      showError(error.code);
      // Handle the error - maybe show a notification to the user
    }
  };

  return (
    <div>
      <MDBContainer>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>Login</MDBCardTitle>
            <div className="d-grid gap-2 col-8 mx-auto">
              <CustomInput
                type="email"
                value={email}
                label="Email..."
                onChange={(e) => setEmail(e.target.value)}
              />
              <CustomInput
                type="password"
                value={password}
                label="Paswoord..."
                onChange={(e) => setPassword(e.target.value)}
              />

              <MDBRow className="mb-4">
                <MDBCol className="d-flex justify-content-center">
                  <MDBCheckbox
                    id="login_remember_me"
                    label="Onthoud mij"
                    defaultChecked
                  />
                </MDBCol>
                <MDBCol>
                  <MDBBtn color="link" onClick={handleForgotPassword}>
                    Paswoord vergeten?
                  </MDBBtn>
                </MDBCol>
              </MDBRow>

              <MDBBtn type="submit" onClick={signInUser} block>
                Sign in
              </MDBBtn>
              <MDBBtn
                color="link"
                rippleColor="dark"
                onClick={() => setSignUp(true)}
              >
                Heb je deze app nog nooit gebruikt?
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
};

const SignInDialog = (props) => {
  const { message, setMessage, setSignUp } = props;
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerValue, setRegisterValue] = useState("");
  const [registerFirstName, setRegisterFirstName] = useState("");
  const [registerLastName, setRegisterLastName] = useState("");
  const [registerDob, setRegisterDob] = useState("");
  const [registerHoursPerWeek, setRegisterHoursPerWeek] = useState(24);
  const [registerContractDate, setRegisterContractDate] = useState("");
  const [registerRole, setRegisterRole] = useState("user");
  const { isErrorModalOpen, showError } = useError();

  const usersRef = collection(db, "users");

  // let log = {
  //   firstName: registerFirstName || null,
  //   lastName: registerLastName || null,
  //   dob: registerDob || null,
  //   contractDate: registerContractDate || null,
  //   hoursPerWeek: registerHoursPerWeek || null,
  //   role: registerRole || null,
  //   email: registerEmail || null,
  // };
  // console.log(log);

  const createUser = async (uid) => {
    console.log(uid);
    let newUser = {
      firstName: registerFirstName || null,
      lastName: registerLastName || null,
      dob: registerDob || null,
      contractDate: registerContractDate || null,
      hoursPerWeek: registerHoursPerWeek || null,
      role: registerRole || null,
      email: registerEmail || null,
    };
    console.log(newUser);
    try {
      await setDoc(doc(usersRef, uid), newUser);
      console.log("createUser:", newUser);
    } catch (err) {
      console.error(err);
    }
  };

  const registerUser = () => {
    createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
      .then((userCredential) => {
        const user = userCredential.user;
        createUser(user.uid);
      })
      .catch((error) => {
        showError(error.code, "Onverwachte fout.");
      });
  };

  const handleChangeHrsPerWk = (value) => {
    const newValue = registerHoursPerWeek + value;
    if (newValue > 36) {
      showError("dasko/36max", "");
      let newValue = 36;
      return newValue;
    } else if (newValue < 0) {
      showError("dasko/0min", "");
      let newValue = 0;
      return newValue;
    }
    setRegisterHoursPerWeek(newValue);
  };

  return (
    <div>
      <MDBContainer>
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>SignUp</MDBCardTitle>
            <div className="d-grid gap-2 col-8 mx-auto">
              <h6>Jouw login details</h6>

              <CustomInput
                type="email"
                value={registerEmail}
                label="Registratie Email..."
                onChange={(e) => setRegisterEmail(e.target.value)}
              />
              <CustomInput
                type="password"
                value={registerPassword}
                label="Registratie Paswoord..."
                onChange={(e) => setRegisterPassword(e.target.value)}
              />

              <hr className="hr" />
              <h6>Jouw persoonlijke details</h6>

              <MDBInput
                size="lg"
                className="mb-4"
                type="text"
                value={registerFirstName}
                label="Voornaam..."
                onChange={(e) => setRegisterFirstName(e.target.value)}
              />

              <MDBInput
                size="lg"
                className="mb-4"
                type="text"
                value={registerLastName}
                label="Achternaam..."
                onChange={(e) => setRegisterLastName(e.target.value)}
              />

              <MDBInput
                size="lg"
                className="mb-4"
                type="date"
                value={registerDob}
                label="Date of birth"
                onChange={(e) => setRegisterDob(e.target.value)}
              />

              <hr className="hr" />
              <h6>Jouw contract details</h6>
              <MDBRow className="mb-5">
                <MDBCol>
                  <MDBInput
                    size="lg"
                    className="mb-4"
                    type="date"
                    value={registerContractDate}
                    label="Contract Datum"
                    onChange={(e) => setRegisterContractDate(e.target.value)}
                  />
                </MDBCol>

                <MDBCol>
                  <MDBRow className="d-flex">
                    <MDBCol className="d-flex align-items-center justify-content-end">
                      <MDBBtn
                        color="danger"
                        onClick={() => handleChangeHrsPerWk(-4)}
                      >
                        -
                      </MDBBtn>
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center justify-content-center">
                      <MDBInput
                        size="lg"
                        className=""
                        type="number"
                        value={registerHoursPerWeek}
                        label="Uur per week"
                        onChange={(e) =>
                          setRegisterHoursPerWeek(e.target.value)
                        }
                      />
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center justify-content-start">
                      <MDBBtn
                        color="success"
                        onClick={() => handleChangeHrsPerWk(+4)}
                      >
                        +
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBBtn type="submit" onClick={registerUser} block>
                Registreer
              </MDBBtn>
              <MDBBtn
                color="link"
                rippleColor="dark"
                onClick={() => setSignUp(false)}
              >
                Deze app eerder gebruikt?
              </MDBBtn>
            </div>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
};
